"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.writeConsoleLog = exports.checkSupportPortal = exports.convertImageToBase64 = exports.checkImage = void 0;
var constants_1 = require("./constants");
function checkImage(url) {
    return new Promise(function (resolve, reject) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = function () {
            resolve({
                status: 'success',
                width: img.width,
                height: img.height,
                src: img.src,
                img: img,
            });
        };
        img.onerror = function () {
            reject({
                status: 'error',
                src: img.src,
                img: img,
            });
        };
        img.src = url;
    });
}
exports.checkImage = checkImage;
function convertImageToBase64(img) {
    var canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    var ctx = canvas.getContext('2d');
    if (ctx) {
        ctx.drawImage(img, 0, 0, img.width, img.height);
    }
    return canvas.toDataURL('image/png').split(',')[1];
}
exports.convertImageToBase64 = convertImageToBase64;
function checkSupportPortal(iconKey, checkLink, locationSupport) {
    return __awaiter(this, void 0, void 0, function () {
        var sessionLocation, response, location_1, base64String, result, error_1, base64String, location_2, _i, _a, _b, key, base64Location;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    sessionLocation = sessionStorage.getItem(iconKey) || '';
                    response = {
                        isSupport: false,
                        location: constants_1.NO_SUPPORT_LOCATION_PREFIX,
                    };
                    if (sessionLocation) {
                        writeConsoleLog('Check SP', 'Get from storage');
                        location_1 = sessionLocation.substring(0, 2);
                        base64String = sessionLocation.substring(2);
                        if (location_1 === constants_1.NO_SUPPORT_LOCATION_PREFIX) {
                            return [2 /*return*/, response];
                        }
                        if (locationSupport[location_1]) {
                            return [2 /*return*/, {
                                    isSupport: base64String === locationSupport[location_1],
                                    location: location_1,
                                }];
                        }
                    }
                    result = null;
                    _c.label = 1;
                case 1:
                    _c.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, checkImage(checkLink)];
                case 2:
                    result = _c.sent();
                    writeConsoleLog('Check SP', 'Success');
                    return [3 /*break*/, 4];
                case 3:
                    error_1 = _c.sent();
                    writeConsoleLog('Check SP', 'Error');
                    return [3 /*break*/, 4];
                case 4:
                    if (result) {
                        base64String = convertImageToBase64(result.img);
                        location_2 = '';
                        for (_i = 0, _a = Object.entries(locationSupport); _i < _a.length; _i++) {
                            _b = _a[_i], key = _b[0], base64Location = _b[1];
                            if (base64Location === base64String) {
                                location_2 = key;
                                break;
                            }
                        }
                        sessionStorage.setItem(iconKey, "".concat(location_2 || constants_1.NO_SUPPORT_LOCATION_PREFIX).concat(base64String));
                        if (location_2) {
                            response.isSupport = true;
                            response.location = location_2;
                        }
                    }
                    return [2 /*return*/, response];
            }
        });
    });
}
exports.checkSupportPortal = checkSupportPortal;
function writeConsoleLog(title, content) {
    console.log("ZynetAppLauncher - ".concat(title, ": "), content);
}
exports.writeConsoleLog = writeConsoleLog;
