"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.POPOVER_CLASS = exports.OVERLAY_CONTENT_CLASS = exports.OVERLAY_CLASS = exports.WIDGET_CLASS = exports.ASSETS = exports.SUPPORT_PORTAL_ICON = exports.EDUCATION_ICON = exports.FORUM_ICON = exports.STORE_ICON = exports.MARKETPLACE_ICON = exports.CIRCLE_ICON = exports.ASTRA_ICON = exports.SECUREPORTER_ICON = exports.NEBULA_ICON = exports.MYZYXEL_ICON = exports.WIDGET_ICON = exports.Language = exports.EntryToken = exports.EnvMode = exports.PREFIX = exports.NO_SUPPORT_LOCATION_PREFIX = void 0;
exports.NO_SUPPORT_LOCATION_PREFIX = '00';
// class prefix
exports.PREFIX = 'zyAppLauncher';
var EnvMode;
(function (EnvMode) {
    EnvMode["aio"] = "aio";
    EnvMode["beta"] = "beta";
    EnvMode["prod"] = "prod";
})(EnvMode = exports.EnvMode || (exports.EnvMode = {}));
var EntryToken;
(function (EntryToken) {
    EntryToken["MYZYXEL"] = "MYZYXEL";
    EntryToken["NEBULA"] = "NEBULA";
    EntryToken["SECUREPORTER"] = "SECUREPORTER";
    EntryToken["ASTRA"] = "ASTRA";
    EntryToken["CIRCLE"] = "CIRCLE";
    EntryToken["MARKETPLACE"] = "MARKETPLACE";
    EntryToken["FORUM"] = "FORUM";
    EntryToken["EDUCATION"] = "EDUCATION";
    EntryToken["SUPPORT_PORTAL_ICON"] = "SUPPORT_PORTAL_ICON";
})(EntryToken = exports.EntryToken || (exports.EntryToken = {}));
var Language;
(function (Language) {
    Language["en"] = "en";
    Language["tw"] = "tw";
    Language["ru"] = "ru";
})(Language = exports.Language || (exports.Language = {}));
/***************************************
 *                Assets
 ***************************************/
exports.WIDGET_ICON = 'widget.svg';
exports.MYZYXEL_ICON = 'myzyxel.svg';
exports.NEBULA_ICON = 'nebula.svg';
exports.SECUREPORTER_ICON = 'secuReporter.svg';
exports.ASTRA_ICON = 'astra.svg';
exports.CIRCLE_ICON = 'circle.svg';
exports.MARKETPLACE_ICON = 'marketplace.svg';
exports.STORE_ICON = 'store.svg';
exports.FORUM_ICON = 'forum.svg';
exports.EDUCATION_ICON = 'education.svg';
exports.SUPPORT_PORTAL_ICON = 'supportPortal.svg';
exports.ASSETS = {
    WIDGET_ICON: exports.WIDGET_ICON,
    MYZYXEL_ICON: exports.MYZYXEL_ICON,
    NEBULA_ICON: exports.NEBULA_ICON,
    SECUREPORTER_ICON: exports.SECUREPORTER_ICON,
    ASTRA_ICON: exports.ASTRA_ICON,
    CIRCLE_ICON: exports.CIRCLE_ICON,
    MARKETPLACE_ICON: exports.MARKETPLACE_ICON,
    STORE_ICON: exports.STORE_ICON,
    FORUM_ICON: exports.FORUM_ICON,
    EDUCATION_ICON: exports.EDUCATION_ICON,
    SUPPORT_PORTAL_ICON: exports.SUPPORT_PORTAL_ICON,
};
/***************************************
 *           DOM classname
 ***************************************/
exports.WIDGET_CLASS = "".concat(exports.PREFIX, "-widget");
exports.OVERLAY_CLASS = "".concat(exports.PREFIX, "-overlay");
exports.OVERLAY_CONTENT_CLASS = "".concat(exports.PREFIX, "-content");
exports.POPOVER_CLASS = "".concat(exports.PREFIX, "-popover");
